<template>
    <div>

        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>
        
        <div>
            <CCard>
                <CRow class="mt-4 mr-3">
                    <CCol sm="5"> </CCol>

                    <CCol sm="7" class="d-none d-md-block">
                        <router-link to="/farmerNewAccount">
                            <CButton
                                color="primary"
                                shape="pill"
                                class="float-right"
                                >+</CButton
                            >
                        </router-link>
                    </CCol>
                </CRow>

                <CCardBody>
                    <div>
                        <CDataTable
                            :hover="true"
                            :striped="true"
                            :border="true"
                            :small="small"
                            :fixed="true"
                            :items="items"
                            :fields="fields"
                            :items-per-page="small ? 5 : 5"
                            pagination
                        >
                            <template #is_active="{ item }">
                                <td>
                                    <CSwitch
                                        class="mt-1 ml-2 mr-2"
                                        color="primary"
                                        :id="item.id"
                                        :checked="item.is_active"
                                        @update:checked="activeUser(item)"
                                        shape="pill"
                                    />
                                </td>
                            </template>

                            <template #Action="{ item }">
                                <td class="d-flex">
                                    <CButton
                                        color="secondary"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="editUser(item)"
                                        ><CIcon name="cil-pencil"
                                    /></CButton>

                                    <CButton
                                        color="danger"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="openModal(item)"
                                        ><CIcon name="cil-trash"
                                    /></CButton>
                                </td>
                            </template>
                        </CDataTable>
                        <CModal
                            title="Delete farmer"
                            :show.sync="myModal"
                            size="sm"
                        >
                            Are you sure you want to proceed?
                            <template #footer>
                                <CButton @click="myModal = false" color="danger"
                                    >Cancel</CButton
                                >
                                <CButton @click="deleteUser()" color="success"
                                    >Delete</CButton
                                >
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
            </CCard>
        </div>
        
    </div>
</template>

<script>
import CustomTable from "../farmerUsers/FarmerTable.vue";
import axios from "axios";

const URL = axios.defaults.baseURL;
const profileUrl = URL + `${"profile/user/details/"}`;
const updateUserUrl = URL + `${"profile/user/edit/"}`;

export default {
    name: "FarmerUsersTable",
    components: { CustomTable },
    data() {
        return {
            items: [],
            myModal: false,
            deleteElement: null,
            checkError: false,
            errorMessage: "",

            fields: [
                {
                    key: "first_name",
                    label: "First name",
                },
                {
                    key: "last_name",
                    label: "Last name",
                },
                {
                    key: "name",
                    label: "Category",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "phone",
                    label: "Contact",
                },
                {
                    key: "city",
                    label: "City",
                },
                {
                    key: "is_active",
                    label: "Status",
                },
                {
                    key: "Action",
                    label: "Action",
                },
            ],
        };
    },
    methods: {
        getShuffledUsersData() {
            return this.items;
        },
        getData() {
            this.axios
                .get(profileUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { params: "farmer" },
                })
                .then((response) => {
                    console.log(response.data.context);
                    this.items = response.data.context;
                })
                .catch((error) => {
                    this.items = [];
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
        activeUser(item) {
            this.axios
                .put(
                    updateUserUrl,
                    { is_active: !item.is_active },
                    {
                        headers: {
                            Authorization: `token ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                        params: { id: item.id },
                    }
                )
                .then((response) => {
                    // this.$router.push("/admin");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        editUser(item) {
            this.$router.push({
                name: "Edit farmer account",
                params: { item },
            });
        },

        deleteUser() {
            this.axios
                .delete(updateUserUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement.id },
                })
                .then((response) => {
                    this.myModal = false;
                    this.deleteElement = null;
                    this.getData();
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        openModal(item) {
            this.myModal = true;
            this.deleteElement = item;
        },
    },

    props: {
        small: Boolean,
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },

    beforeMount() {
        this.getData();
    },
};
</script>
